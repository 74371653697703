@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-items.active {
  background: #7c3aed;
  color: #fff;
}

.react-calendar {
  border: 0px solid !important;
}

.react-calendar__tile--active {
  background: #7c3aed !important;
}


/* Add the following CSS to your global styles or main CSS file */

/* Thin scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #9588ff;
  /* Light purple color */
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Background color */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #9588ff #f2f2f2;
}

*::-moz-scrollbar-thumb {
  background-color: #9588ff;
  /* Light purple color */
  border-radius: 4px;
}

*::-moz-scrollbar-track {
  background-color: #f2f2f2;
  /* Background color */
}

.react-calendar {
  width: 100% !important;
}

@media (max-width: 1021px) {
  .custom_bredcrumb {
    visibility: visible !important;
    display: flex !important;
  }
}

@media (max-width: 767.98px) {
  .product-up-box, .pagination-box {
    /* display: flex; */
    flex-wrap: wrap;
  }
}

/* @media (prefers-color-scheme: dark) {
  .dark\:text-white{
    color: black !important;
  }

 
} */



